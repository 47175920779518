import React, { ReactNode } from 'react';

import Tile from '../../assets/images/tile.svg';

import './VersionItem.scss';

interface VersionItemProps {
  title: string;
  description: ReactNode;
}

const VersionItem: React.FC<VersionItemProps> = ({ title, description }) => (
  <div className="version-item">
    <div className="version-item__icon">
      <img src={Tile} alt="" />
    </div>
    <div className="version-item__info">
      <div className="title">{title}</div>
      <p className="description">{description}</p>
    </div>
  </div>
);

export default VersionItem;
