import React from 'react';
import { DateTime, DateObject } from 'luxon';
import Container from '../Container';
import Button from '../Button';

import './UpdatesItem.scss';

interface UpdatesItemProps {
  title: string;
  date: DateObject;
  link: string;
  linkLabel: string;
  children: React.ReactNode;
}

const UpdatesItem: React.FC<UpdatesItemProps> = ({ title, date, link, linkLabel, children }) => {
  const dt = DateTime.fromObject(date);

  return (
    <article className="updates-item">
      <Container className="version-container">
        <div className="version-info">
          <h2 className="title">{title}</h2>
          <p className="date">
            <time dateTime={dt.toISO()}>{dt.toLocaleString(DateTime.DATE_HUGE)}</time>
          </p>
          <Button outlined arrow target="_blank" href={link}>
            {linkLabel}
          </Button>
        </div>
        <div className="version-content">{children}</div>
      </Container>
    </article>
  );
};

export default UpdatesItem;
